import { SongFiles } from "../model/Song";
import { useParams } from "react-router-dom";
import "./DownloadButton.css";
import ReactPixel from "react-facebook-pixel";

export const DownloadButton = ({ songFiles }: { songFiles: SongFiles }) => {
  const { songId } = useParams() as { songId: any };

  const urlBase = "/songs/" + songId + "/";

  return (
    <div className={"download-container"}>
      {songFiles.mp3 && (
        <a
          className="service-btn"
          target="_blank"
          href={urlBase + songFiles.mp3}
          rel="noreferrer"
          download
          onClick={() => ReactPixel.trackCustom("download-mp3")}
        >
          mp3
        </a>
      )}
      {songFiles.wav && (
        <a
          className="service-btn"
          target="_blank"
          href={urlBase + songFiles.wav}
          rel="noreferrer"
          download
          onClick={() => ReactPixel.trackCustom("download-wav")}
        >
          wav
        </a>
      )}
    </div>
  );
};
