import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter, Route, Routes } from "react-router-dom";
import "normalize.css";
import NothingFound from "./pages/NothingFound";

const css = ` 
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700;900&display=swap');
`;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>
      <style>{css}</style>
      <Routes>
        <Route path="/:songId" element={<App />} />
        <Route path="/" element={<NothingFound />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
