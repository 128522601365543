import "./AlbumCover.css";
import { useEffect, useRef, useState } from "react";
import { IoPauseCircleSharp, IoPlayCircleSharp } from "react-icons/io5";
import { SongFiles } from "../model/Song";
import { Settings } from "../model/Settings";

interface AlbumCoverProps {
  songId: string | undefined;
  songFiles: SongFiles;
  settings: Settings | undefined;
}

const AlbumCover = (props: AlbumCoverProps) => {
  const coverBg = {
    backgroundImage: 'url("/songs/' + props.songId + '/cover.jpg")',
  };

  const albumCover = {
    backgroundImage: 'url("/songs/' + props.songId + '/cover.jpg")',
  };

  const [playing, setPlaying] = useState(false);
  const [showPlay, setShowPlay] = useState(false);

  const url = props.songFiles.crop && "/songs/" + props.songId + "/" + props.songFiles.crop;

  const audioRef = useRef(new Audio(url));

  const play = () => {
    setPlaying(true);
    audioRef.current.play();
  };

  const pause = () => {
    setPlaying(false);
    audioRef.current.pause();
  };

  useEffect(() => {
    if (props.settings && props.settings.allowMusicSamples) {
      setShowPlay(props.settings.allowMusicSamples);
    }
  }, [props.settings]);

  return (
    <div className="albumcover-container">
      <div className="albumcover-coverBg" style={coverBg}></div>
      <div className="albumcover-cover" style={albumCover}>
        {props.songFiles.crop && <div className="albumcover-playButton">
          {showPlay && !playing && (
            <div onClick={() => play()}>
              <IoPlayCircleSharp size={80} />
            </div>
          )}
          {showPlay && playing && (
            <div onClick={() => pause()}>
              <IoPauseCircleSharp size={80} />
            </div>
          )}
        </div>}
      </div>
    </div>
  );
};

export default AlbumCover;
