import CSS from "csstype";
import ServiceLink from "./ServiceLink";
import "./SidePanel.css";
import { DownloadButton } from "./DownloadButton";
import { Links, SongFiles } from "../model/Song";

const fortuanStyle: CSS.Properties = {
  fontFamily: "Montserrat, sans-serif",
  margin: 0,
  fontWeight: 900,
  fontSize: "1.5rem",
};

const songStyle: CSS.Properties = {
  fontFamily: "Montserrat, sans-serif",
  fontWeight: 300,
  margin: 0,
  fontSize: "1.5rem",
};

const sidebarHeading: CSS.Properties = {
  display: "flex",
  flexDirection: "column",
  padding: "2.5rem",
};

const sidebarLinks: CSS.Properties = {
  display: "flex",
  flexDirection: "column",
  padding: "0 2.5rem",
};

const sidebarFooter: CSS.Properties = {
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  fontFamily: "Montserrat, sans-serif",
  margin: 0,
  fontSize: "0.8rem",
  padding: "2.5rem 0",
  borderTop: "2px solid #e7eaec",
};

const headingStyle: CSS.Properties = {
  fontWeight: 700,
  margin: 0,
};

interface SidePanelProps {
  title: string | undefined;
  links: Links;
  songFiles: SongFiles;
  lyricsAuthor?: string;
  musicAuthor?: string;
  isrc?: string;
}

const SidePanel = (props: SidePanelProps) => {
  return (
    <div className="sidepanel-container">
      <div style={sidebarHeading}>
        <h1 style={fortuanStyle}>Fortuan</h1>
        <h2 style={songStyle}>{props.title}</h2>
      </div>
      <div style={sidebarLinks}>
        {Object.keys(props.links).map((serviceName) => (
          <ServiceLink
            serviceName={serviceName}
            serviceUrl={props.links[serviceName as keyof Links]?.url}
            enabled={props.links[serviceName as keyof Links]?.enabled}
            key={serviceName}
          />
        ))}
        <DownloadButton songFiles={props.songFiles} />
        {(props.isrc || props.musicAuthor || props.lyricsAuthor) && (
          <div style={sidebarFooter}>
            {props.isrc && (
              <div>
                <h3 style={headingStyle}>ISRC</h3>
                <p style={{ margin: 0 }}>{props.isrc}</p>
              </div>
            )}
            {props.musicAuthor && (
              <div>
                <h3 style={headingStyle}>Music</h3>
                <p style={{ margin: 0 }}>{props.musicAuthor}</p>
              </div>
            )}
            {props.lyricsAuthor && (
              <div>
                <h3 style={headingStyle}>Lyrics</h3>
                <p style={{ margin: 0 }}>{props.lyricsAuthor}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SidePanel;
