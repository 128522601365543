import "./ServiceLink.css";
import ReactPixel from "react-facebook-pixel";

interface ServiceLinksProps {
  serviceName: string;
  serviceUrl?: string;
  enabled?: boolean;
}

const ServiceLink = (props: ServiceLinksProps) => {
  const serviceList: any = {
    youtube: {
      src: "youtube-light-logo.png",
      alt: "Youtube icon",
      btn: "play",
    },
    spotify: {
      src: "spotify-light-logo.png",
      alt: "Spotify icon",
      btn: "play",
    },
    itunes: {
      src: "applemusic-light-logo.png",
      alt: "Apple music icon",
      btn: "play",
    },
    "apple-music": {
      src: "applemusic-light-logo.png",
      alt: "Apple music icon",
      btn: "play",
    },
    tidal: {
      src: "tidal-light-logo.png",
      alt: "TIDAL icon",
      btn: "play",
    },
  };

  if (!props.serviceName || !(props.serviceName in serviceList)) {
    return null;
  }

  return (
    <div className="container">
      <img
        className="service-img"
        src={require(`/src/assets/img/services/${
          serviceList[props.serviceName].src
        }`)}
        alt={serviceList[props.serviceName].alt}
      />
      <a
        id={props.serviceName}
        className="service-btn"
        style={{
          cursor: props.enabled ? "pointer" : "not-allowed",
          opacity: props.enabled ? 1 : 0.5,
          pointerEvents: props.enabled ? "auto" : "none",
        }}
        target="_blank"
        href={props.enabled ? props.serviceUrl : "#"}
        rel="noreferrer"
        onClick={() => ReactPixel.trackCustom(props.serviceName + "-play")}
      >
        {props.enabled ? serviceList[props.serviceName].btn : "coming soon"}
      </a>
    </div>
  );
};

export default ServiceLink;
