import "./NothingFound.css";

const NothingFound = () => {
  return (
    <div className="nf-container">
      <div className="nf-text">
        <h1>Nothing found</h1>
        <p>
          Unfortunately song you are looking for is not yet written. Please
          contact Kubo.
        </p>
      </div>
    </div>
  );
};

export default NothingFound;
