import AlbumCover from "./components/AlbumCover";
import SidePanel from "./components/SidePanel";
import NothingFound from "./pages/NothingFound";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./App.css";
import ReactPixel from "react-facebook-pixel";
import { Links, Song, SongFiles } from "./model/Song";
import { Settings } from "./model/Settings";

const App = () => {
  const { songId } = useParams() as { songId: any };
  const [songs, setSongs] = useState<Song[]>();
  const [settings, setSettings] = useState<Settings>();

  const options = {
    autoConfig: true,
    debug: false,
  };

  const getSongs = () => {
    fetch("songs/songs.json").then((res) => {
      res.json().then((res) => {
        setSongs(res);
      });
    });
  };

  const getData = () => {
    fetch("songs/settings.json").then((res) => {
      res.json().then((res) => {
        setSettings(res);
      });
    });
  };

  useEffect(() => {
    getSongs();
    getData();
  }, []);

  if (settings && settings.facebookPixelId) {
    ReactPixel.init(settings.facebookPixelId, undefined, options);
    ReactPixel.pageView();
  }

  if (!songs) {
    return null;
  }

  if (!(songId in songs)) {
    return <NothingFound />;
  }

  return (
    <div className="app-container">
      <AlbumCover
        settings={settings}
        songId={songId}
        songFiles={songs[songId].songFiles as SongFiles}
      />
      <SidePanel
        title={songs[songId].title as unknown as string}
        links={songs[songId].links as unknown as Links}
        songFiles={songs[songId].songFiles as SongFiles}
        lyricsAuthor={songs[songId].lyricsAuthor as unknown as string}
        musicAuthor={songs[songId].musicAuthor as unknown as string}
        isrc={songs[songId].isrc as unknown as string}
      />
    </div>
  );
};

export default App;
